import React, { forwardRef } from 'react';
import Button from '@/components/shared/Button/Button';
import { TwoColumns } from '@/components/layout/two-columns';
import { TitleSlash } from '@/components/shared/title-slash';
import { cn } from '@/lib/utils';

interface LeftRightProps {
  title: string;
  subtitle: string;
  description: string;
  imageSrc: string;
  imageSide?: 'left' | 'right';
  cta?: {
    label: string;
    path: string;
  };
  className?: string;
}

const LeftRight = forwardRef<HTMLDivElement, LeftRightProps>(
  ({ title, subtitle, description, imageSrc, imageSide = 'left', cta, className }, ref) => {
    const Content = () => (
      <div
        className={cn('flex flex-col w-full p-5 px-0 gap-2.5 lg:pl-20', {
          'lg:pr-20 lg:pl-0': imageSide === 'right',
        })}
      >
        <TitleSlash title={title} />
        <h3 className="font-sans text-xs uppercase leading-relaxed text-loop-purple">{subtitle}</h3>
        <p className="font-sans text-base text-black">{description}</p>
        {cta && (
          <Button tag="button" href={cta.path} className="mt-2">
            {cta.label}
          </Button>
        )}
      </div>
    );

    const Image = () => (
      <img
        className="h-[360px] w-full max-w-[75rem] justify-self-center object-cover"
        src={imageSrc}
        alt={title}
      />
    );

    return (
      <TwoColumns
        ref={ref}
        left={imageSide === 'left' ? <Image /> : <Content />}
        right={imageSide === 'right' ? <Image /> : <Content />}
        className={className}
      />
    );
  },
);

export { LeftRight };
